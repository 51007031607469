import * as React from "react"
import { useEffect, useState } from "react"
import Link from "./Link"
import { LinkProps } from "@chakra-ui/react"
type Props = {
  email: string
} & LinkProps
const Email = ({ email, ...props }: Props) => {
  const [state, setState] = useState({
    email: "[email protected]",
  })
  useEffect(
    () =>
      setState({
        email: email,
      }),
    []
  )
  return (
    <Link href={`mailto:${state.email}`} {...props}>
      {state.email}
    </Link>
  )
}

export default Email
