import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

type Props = {
  children: React.ReactNode
} & FlexProps

const StandardColumnLayout = ({ children, ...props }: Props) => {
  return (
    <Flex flex="1 1 auto" flexDirection="column" {...props}>
      {children}
    </Flex>
  )
}

export default StandardColumnLayout
