import { Box, Divider, Flex, Heading, Icon, Text } from "@chakra-ui/react"
import ContactHeroImage from "components/images/ContactHeroImage"
import Footer from "components/layout/Footer"
import Header from "components/layout/Header"
import LayoutBase from "components/layout/LayoutBase"
import SEO from "components/layout/SEO"
import StandardColumnLayout from "components/layout/StandardColumnLayout"
import Email from "components/ui/Email"
import Link from "components/ui/Link"
import MaxWidthWrapper from "components/ui/MaxWidthWrapper"
import { personalInfo } from "data/cv/data"
import { PageProps } from "gatsby"
import React from "react"
import { FaDiscord, FaEnvelope, FaLinkedin, FaTelegram } from "react-icons/fa"

const Contact = (props: PageProps) => {
  const contactChannels = [
    {
      type: "Telegram",
      icon: FaTelegram,
      link: "https://t.me/mnowotnik",
      description: "",
    },
    {
      type: "Discord",
      icon: FaDiscord,
      link: "https://discordapp.com/users/mnowotnik#3645",
      description: "",
    },
    {
      type: "LinkedIn",
      icon: FaLinkedin,
      link: "https://www.linkedin.com/in/mnowotnik/",
      description: "",
    },
  ]
  return (
    <LayoutBase backgroundColor="white">
      <SEO title="MN: Contact"/>
      <Header
        bgColor={{ base: "primary.main", md: "white" }}
        location={props.location}
        position="fixed"
        top={0}
        left={0}
        right={0}
      />
      <Box
        flex="1 1 100%"
        display={{ base: "flex", md: "none" }}
        alignItems="center"
      >
        <ContactHeroImage />
      </Box>
      <StandardColumnLayout pt={{ base: 0, md: 32 }}>
        <Box w="100%" bgColor="primary.100" py={8}>
          <MaxWidthWrapper
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDir={{ base: "column-reverse", md: "row" }}
            pb={0}
            pt={0}
          >
            <Box
              flex="1 1 50%"
              display={{ base: "none", md: "flex" }}
              alignItems="center"
              pt={{ base: 8, md: 0 }}
            >
              <ContactHeroImage
                style={{
                  height: 250,
                }}
              />
            </Box>
            <Box px={3} display={{ base: "none", md: "block" }} />
            <Box flex="1 1 50%">
              <Heading textAlign="center" as="h1" fontSize="5xl">
                Contact
              </Heading>
              <Text pt={4}>
                Here you can find various communication channels I am available
                at. The easiest way to reach me is email, but I'm also reachable
                on other platforms. Keep in mind I need to accept your
                invitation on Discord or Telegram first, so give me heads up via
                email that you want to connect in this way.
              </Text>
            </Box>
          </MaxWidthWrapper>
        </Box>

        <MaxWidthWrapper maxW="500px">
          <Flex display="flex" fontSize={["lg", "2xl"]} alignItems="center">
            <Box bg="secondary.main" p={2} lineHeight={0} borderRadius="50%">
              <Icon as={FaEnvelope} color="white" />
            </Box>
            <Email email={personalInfo.email} pl={3} />
          </Flex>
          {/* <Text pt={4} fontSize="lg"></Text> */}
          {contactChannels.map(ch => (
            <Box key={ch.type}>
              <Divider my={8} />
              <Flex display="flex" fontSize={["lg", "2xl"]} alignItems="center">
                <Box
                  bg="secondary.main"
                  p={2}
                  lineHeight={0}
                  borderRadius="50%"
                >
                  <Icon color="white" as={ch.icon} />
                </Box>
                <Link pl={3} href={ch.link}>
                  {ch.type}{" "}
                </Link>
              </Flex>
              {/* <Text pt={4} fontSize="lg">
              {ch.description}
            </Text> */}
            </Box>
          ))}
          <Box pb={20} />
        </MaxWidthWrapper>
      </StandardColumnLayout>
      <Footer />
    </LayoutBase>
  )
}

export default Contact
